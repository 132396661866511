import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';

const buildStyles   = makeStyles(theme => ({
  root  : {
    padding: theme.spacing(1),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    // height: "100%",
    // width: "100%",
  },
  errorCard: {
    width: "40%",
    border: `1px solid ${theme.palette.error.dark}`, //`
    borderRadius: 5,
    // borderColor: theme.palette.error.main,
    background: theme.palette.common.white,
  },
  errorContent: {
    background: `${theme.palette.error.light}33`, //`
  },
  errorHeader: {
    backgroundColor: `${theme.palette.error.light}55`, //`
    borderBottomColor: theme.palette.error.main,
    color: theme.palette.error.dark,
    padding: `${theme.spacing(1)}px ${theme.spacing(0)}px`, //`
  },
  errorBody: {
    padding: theme.spacing(2),
  },
  title: {
    fontSize: "1.2rem",
  },
  errorText: {
    textAlign: "center",
    fontSize: "0.9rem",
    fontWeight: 300,
  },
  details: {
    backgroundColor: `${theme.palette.error.light}11`, //` 
    width: "100%",
  }
}));

const ErrorCard = ({message, details}) => {
  const classes   = buildStyles();

  return (
    <Grid id="error-view" container justify="center" className={classes.root}>
      <Grid container className={classes.errorCard}>
        <Grid container className={classes.errorContent}>
          <Grid container justify="center" className={classes.errorHeader}>
            <Typography className={classes.title}>Error</Typography>
          </Grid>
          <Grid container justify="center" className={classes.errorBody}>
            <Typography className={classes.errorText}>{message}</Typography>
          </Grid>
          {details && 
            <Grid container className={classes.errorBody}>
              <Accordion variant="outlined" className={classes.details}>
                <AccordionSummary>
                  <Typography className={classes.errorText}>Details</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography className={classes.errorText}>{details}</Typography>
                </AccordionDetails>
              </Accordion>          
            </Grid>        
          }
        </Grid>
      </Grid>
    </Grid>
  );
}

export default ErrorCard;