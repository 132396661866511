import React from 'react';
import AppHeader from '../app-header';
import { AuthProvider } from '../auth/auth-provider';
import VodFrame from './vod-frame';


function VodContainer () {
  return (
    <div className="app">
        <AuthProvider>
          <AppHeader/>
          <VodFrame/>
        </AuthProvider>
    </div>
  );
}

export default VodContainer;