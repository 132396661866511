import { combineReducers } from 'redux';
// import { connectRouter } from 'connected-react-router';
// import { routerReducer } from 'react-router-redux';
// import appReducer from './app-reducer';
import rptReducer from './reports-reducer';

export default function configureReducers(history){

    const reducers = {
        // router          : connectRouter(history),
        //app             : appReducer,
        reports         : rptReducer,
    };

    const rootReducer = combineReducers({
        ...reducers,
        // routing: routerReducer
    });

    return rootReducer;
}