import React, { useEffect, useRef } from "react";
import { useState } from 'react';
import { useLocation } from 'react-router-dom';

export function useQueryString() {
  const urlp = new URLSearchParams(useLocation().search);
  const sort = urlp.get("sort") || "";
  const filter = urlp.get("filter") || "";
  const find = urlp.get("find") || "";       //TODO: Need to convert this to "find" since search is a keyword that will make it tough

  let result = {};
  if (sort) result.sort = sort;
  if (filter) result.filter = filter;
  if (find) result.find = find; //TODO: Need to convert this to "find" since search is a keyword that will make it tough

  return [result, updateUrlSearch];
}

export function usePage(defaultPageSize) {
  const [page, setPage] = useState({ number: 0, size: defaultPageSize || 20 });

  function change(pageNum, pageSize) {
    if (pageNum === undefined && pageSize === undefined) return;
    const myNum = pageNum || 0; //if they've provided a new page size, reset to page 0
    const mySize = pageSize || page.size;
    setPage({ number: myNum, size: mySize });
  }

  return [page, change];
}

export function updateUrlSearch(key, value) {
  const url = new URL(window.location.href);
  if (url.searchParams.has(key) && !value)
    url.searchParams.delete(key);
  else if (Boolean(value))
    url.searchParams.set(key, value);

  const relative = url.href.replace(window.location.origin, ""); //need to remove the origin for sake of the history
  return relative;
}

export function useInterval(callback, delay) {
  const savedCallback = useRef();

  // Remember the latest function.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

export function useIsEmbeded(){
  const location = useLocation();
  const pathName = location.pathname.toLowerCase();
  const isEmbeded = pathName.indexOf("/player") > -1 || pathName.indexOf("/_embed") > -1;
  return isEmbeded;
}

export const AnchorPositions = {
  bottomRight: {
    getContentAnchorEl: null,
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "right",
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "right",
    },
  },
  bottomLeft: {
    getContentAnchorEl: null,
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "left",
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "left",
    },
  },
  bottomCenter: {
    getContentAnchorEl: null,
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "center",
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "center",
    },
  },
};

//==================
// Anchor Hook
// export interface IAnchorHandler {
//   anchor  : object,
//   isOpen  : boolean,
//   onOpen  : React.MouseEventHandler<HTMLElement>,
//   onClose : (any) => void,
//   positionProps   : "bottomRight" | "bottomLeft" | "bottomCenter" | undefined,
// }

export function useAnchor(position = "bottomRight")//: [
    // Element | null,
    // boolean,
    // React.MouseEventHandler<HTMLElement>,
    // (ignored?: any) => void,
    // any] 
  {
  const [anchor, setAnchor] = React.useState(null);

  const onOpen = (e) => {
    setAnchor(e.currentTarget);
  };

  const onClose = () => {
    setAnchor(null);
  };
  const positionProps = AnchorPositions[position];

  return [anchor, Boolean(anchor), onOpen, onClose, positionProps];
}