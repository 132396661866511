import React, { Fragment } from 'react';
import clsx from 'clsx';
import { Helmet } from 'react-helmet';
import Favicon from 'react-favicon';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import { useCustomizations } from "@personicom/customizations";



//TODO: Why do we use this at all, header should be shared across the SWA's.

//***********/
//NOTE: Not used by player routes
// only used in /reports and /app routes

const AppHeader = () => {
    const classes = buildStyles();
    const { strings, links, flags, images, blobUrl } = useCustomizations();
    const favicon = blobUrl(images.favicon);
    const logo = blobUrl(images.logo);

    return (
        <Fragment>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{strings.header?.title}</title>
                <meta name="description" content={strings.header?.title}></meta>
                <link rel="canonical" href={links.homeUrl} />
                <link rel="apple-touch-icon" href={favicon} />
            </Helmet>
            <Favicon url={favicon} />

            <CssBaseline />
            <Grid item xs={12} sm container className={classes.headerRoot}  >
                <Grid item xs container direction="row" spacing={2} >
                    <Grid item xs align={(flags && flags.centerLogo) ? "center" : ""}>
                        <a href={links.homeUrl} target="_blank" rel="noreferrer">
                            <img className={clsx(flags && flags.responsiveLogo && "img-responsive", classes.logoImage)} src={logo} alt={strings.logoAlt} />
                        </a>
                    </Grid>
                    <Grid item>
                        {strings.hasOwnProperty('headerInfoText') ? <Link href={links.homeUrl} className={classes.headerInfoText} target="_blank">{strings.headerInfoText}</Link > : null}
                    </Grid>
                </Grid>
            </Grid>
        </Fragment>
    );
}

export default AppHeader;

const buildStyles = makeStyles(theme => ({
    headerRoot: {
        padding: "20px 40px 16px 40px",
        backgroundColor: `${theme.styles.header?.backgroundColor ?? theme.styles.page.backgroundColor}`,
        borderBottom: theme.styles.header?.borderBottom ?? `${theme.styles.header?.borderBottomWidth ?? "5px"} solid ${theme.styles.page.borderColor}`, //`
        [theme.breakpoints.down('xs')]: {
            alignItems: "center",
            justifyContent: "center",
        }
    },
    headerInfoText: {
        fontSize: 24,
        fontWeight: 700,
        color: theme.styles.header?.color ?? theme.styles.page.color,
        [theme.breakpoints.down('xs')]: {
            fontSize: 20,
            textAlign: "center",
        },
    },
    logoImage: {
        height: `${theme.styles.header?.logo?.height ?? "70px"}`, //`
        maxHeight: `${theme.styles.header?.logo?.maxHeight ?? "none"}`, //`
        marginLeft: 15,
        [theme.breakpoints.down('xs')]: {
            height: 55,
            marginLeft: 0,
        }
    }
}));