import React from 'react';
import ReactDOM from 'react-dom';
import _ from 'lodash';
import App from './app';
import { Provider } from 'react-redux';
import configureStore from './store/configure-store';
import { BrowserRouter } from 'react-router-dom';
import TelemetryProvider from 'helpers/telemetry-provider';


const rootElement = document.getElementById('root');
const store = configureStore();

const render = () => {
  ReactDOM.render(
    <Provider store={store}>
      <BrowserRouter>
        <TelemetryProvider instrumentationKey="637aa50b-6e68-4d17-959a-5bf190c74c3f">
            <App />
        </TelemetryProvider>
      </BrowserRouter>
    </Provider>
    , rootElement);
};


//Render the app the first time through
render();

//Try to accept any HMR changes, and not re-render the whole app...
if (module.hot) {
  module.hot.accept('./app', () => {
    render();
  });
}
