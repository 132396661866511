import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { useAuth } from 'auth/auth-provider';
import AdminSignIn from 'auth/admin-signin';
import AuthContainer from 'auth/auth-container';
import ErrorBoundary from 'helpers/error-boundary';
import { getEnvironment, getStaticWebAppUrl, getSearchParam } from 'helpers/api-helper';
import ErrorCard from 'helpers/error-card';
import { useLocation } from 'react-router-dom';
import { useCustomizations } from "@personicom/customizations";


const ReportsFrame = () => {
  const { isAuthenticated, user } = useAuth();
  const classes   = buildStyles();
  const [reportAppUrl, setReportAppUrl] = useState(null);
  const [error, setError] = useState(null);
    const location = useLocation();
    const { subdomain } = useCustomizations();


  //--
  // Effect to load the url for the iframe based on the environment we're running in.
  useEffect(() => {
    async function doEffect(){
      try{
        const env = getEnvironment(true).toLowerCase();
        let url = await getStaticWebAppUrl(env, "reporting");        

        if(!url){
          setError(["Unfortunately, the reporting module could not be loaded. Technical support has been notified.", "Could not get reporting app url.  Either environments.json is missing or invalid, or it does not contain key for environment: " + env]);
          return;
        }

        const rowId = getSearchParam("rowid", true);
        const rowPart = rowId ? `&rowid=${rowId}` : "";
        console.log("found row id: ", rowId);

        //Sample: http://reporting.universitymsg.com.localhost:3003/pittcomingsoon/
          url = `${url}/${subdomain}/?en=${env}&cl=universitymsg.com&${rowPart}&token=${encodeURIComponent(user.token)}&parentWindow=${encodeURIComponent(window.location.href)}`
        
        if(rowId) console.log("final url with row id: ", url);

        setReportAppUrl(url);
      }
      catch(ex){
        console.error("attempting to build the report static web app url", ex);
        setError(["Unfortunately, the reporting module could not be loaded. Technical support has been notified.", ex.toString()]);
      }
    }
  
    if(!reportAppUrl && user && user.token){
      doEffect()
    }
  }, [reportAppUrl, user]);

  console.log("user", user);
  return (
    <Grid container direction="column" className={classes.appBody}>
      {!isAuthenticated &&
        <AdminSignIn />
      }
      {isAuthenticated &&
        <AuthContainer>
          <ErrorBoundary>
            {error && 
              <ErrorCard message={error[0]} details={error[1]} />
            }
            {!error && reportAppUrl && 
              <div className={classes.iframeContainer}>
                <iframe title="Reporting Body" className={classes.iframe} src={reportAppUrl} allow="clipboard-write" width="100%"></iframe>
              </div>
            }
          </ErrorBoundary>
        </AuthContainer>
      }
    </Grid>
  );
}

export default ReportsFrame;

const buildStyles   = makeStyles(theme => ({
  appBody: {
    flexWrap: "nowrap",
    textAlign: "center",
    padding: 5,
    borderBottom: `5px solid ${theme.schoolColors.borderColor}` //`
  },
  iframeContainer: {
    padding: theme.spacing(2),
  },
  iframe: {
    height: "calc(100vh - 210px)",
    border: "none",
  }
  
}));