import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import VodContainer from './vod/vod-container.js';
import ReportsContainer from './components/reports/reports-container';
import PlayerFrameEmbeded from 'player/player-frame-embeded.js';

function AppRouting() {

  return (
    <Router>
      <Switch>
        <Route path={["/reports", "/:subdomain/reports"]}>
            <ReportsContainer/>
        </Route>
        <Route path={["/app/:application", "/app", "/:subdomain/app/:application", "/:subdomain/app"]}>
            <VodContainer/>
        </Route>
        <Route path={["/player", "/:subdomain/player", "/_embed", "/:subdomain/_embed"]}>
          <PlayerFrameEmbeded/>
        </Route>
        <Route>
          {/* //This is our default player route, for Landing mode.*/}
          <PlayerFrameEmbeded/>
        </Route>
      </Switch>
    </Router>
  );
}

export default AppRouting;

/*
 *  Sample URLS:
 *  Embeded : 
 *    https://pittgrad.universitymsg.com/player/?sid=4061609
 *    https://pittgrad.universitymsg.com/_embed/?sid=4061609
 *  Default :  
 *    https://welcometopitt.universitymsg.com/?sid=209178 *  
 */