import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

const fbConfig = {
  apiKey: "AIzaSyBkfcMHFNFsiwu6_mkdyCXXOIxDYWbJPYo",
  authDomain: "personicom-umsg.firebaseapp.com",
  projectId: "personicom-umsg",
  databaseURL: "https://personicom-umsg.firebaseio.com",
  storageBucket: "personicom-umsg.appspot.com",
  messagingSenderId: "911835698555",
  appId: "1:911835698555:web:30188dad25983e2b3659de"
};

const firebaseApp = firebase.initializeApp(fbConfig);

export default firebaseApp;