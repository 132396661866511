import React, { useContext, useEffect, useMemo, useState } from "react";
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import { getSchoolFromUrl } from "../helpers/general-helpers";
import { authApi } from "./auth-api";
import firebaseApp from "./firebase-config";

const createAppUser = async (user) => {
  if(user === null) return user;
  else{
    const authToken = await firebaseApp.auth().currentUser.getIdToken(false);
    console.log(authToken);
    
    const appUser = {
      uid: user.uid,
      displayName: user.displayName,
      email: user.email,
      emailVerified: user.emailVerified,
      phoneNumber: user.phoneNumber,
      photoUrl: user.photoUrl,       
      token: authToken,
    };
    return appUser;
  }
}

//Context to hold the auth info for the app
export const AuthContext = React.createContext(null);

//Context Provider that will provide context to the relevant portion of the app
export const AuthProvider = ({children}) => {
  const [isInitialized, setInitialized] = useState(false);
  const [user, setUser] = useState(null);
  const school = useMemo(() => getSchoolFromUrl(), []);

  const onAuthChanged = async (user) => {
    //Validate that this user is permitted for this school
    const isValid = !user || await authApi.checkWhitelist(school, user.email);
    
    if(isValid === true){
      const appUser = await createAppUser(user);
      setUser(appUser);
    }
    else{
      signOut();  //sign the user out if they don't belong in this school
    }

    setInitialized(true); //indicate that we're setup
  }

  useEffect(() => {
    //setup a listener that will update the context when the user logs in or out.
    firebaseApp.auth().onAuthStateChanged(onAuthChanged);    
  }, []);

  //===
  // Sign In and Sign Out methods
  const createAccount = async (emailAddress, password) => {
    try {
      const isValid = await authApi.checkWhitelist(school, emailAddress);
      if(isValid === true){
        const result = await firebaseApp.auth().createUserWithEmailAndPassword(emailAddress, password);
        return result;
      }
      else{
        return {isError: true, error: {message: "You are not permitted to access this feature.  Please contact your administrator if you believe this is a mistake."}};
      }
    }
    catch (error) {
      return {isError: true, error};
    }
  };

  const signInEmail = async (emailAddress, password) => {
    if(!user){
      try {
        const result = await firebaseApp.auth().signInWithEmailAndPassword(emailAddress, password, );
        if(result && result.user){
          const isValid = await authApi.checkWhitelist(school, emailAddress);
          if(isValid === true){
            return result;
          }
          else{
            await firebaseApp.auth().signOut();
            return {isError: true, error: {message: "You are not permitted to access this feature.  Please contact your administrator if you believe this is a mistake."}};
          }        
        }
      }
      catch (error) {
        // console.error("Failed to login with email.", error);
        return {isError: true, error};
      }
    }
    return Promise.reject({isError: true, error: "User already authenticated"});
  };

  const signOut = async () => {
    if (user) {
      return await firebaseApp.auth().signOut();
    }
    return Promise.reject("No authenticated user to sign out");
  }

  const ctx = {
    user,
    isAuthenticated: Boolean(user),
    createAccount,
    signInEmail,
    signOut,
  };

  if(!isInitialized){
    return (
      <Grid container direction="column" alignItems="center" style={{marginTop: "10%"}}>
        <CircularProgress color="primary" />
        <Typography component="span" style={{marginTop: "8px"}}>Initializing...</Typography>
      </Grid>
    )
  }

  return (
    <AuthContext.Provider value={ctx}>
      {children}
    </AuthContext.Provider>
  );
};

//Hook that will provide auth information
// NOTE: This can only run from inside the AuthProvider component.
export function useAuth() {
  const ctx = useContext(AuthContext);

  return {
    isAuthenticated: Boolean(ctx.isAuthenticated),
    user: ctx.user || null,
  };
}

//Hook that will provide auth information and actions (sign in, sign out)
// NOTE: This can only run from inside the AuthProvider component.
export function useAuthWithActions() {
  const ctx = useContext(AuthContext);
  return ctx;
}