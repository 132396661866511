import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import ErrorBoundary from 'helpers/error-boundary';
import { getEnvironment, getStaticWebAppUrl } from 'helpers/api-helper';
import { getPlayerParamsFromUrl } from 'helpers/general-helpers';
import ErrorCard from 'helpers/error-card';
import { useIsEmbeded } from 'helpers/general-hooks';
import { useCustomizations } from "@personicom/customizations";

const PlayerFrameEmbeded = () => {
  const [playerAppUrl, setPlayerAppUrl] = useState(null);
  const [error, setError] = useState(null);
  const isEmbeded = useIsEmbeded();
  let { campaignId, sid } = useParams();
    const classes = buildStyles();
    const { subdomain } = useCustomizations();
  
  const { sidQueryParam, campaignQueryParam, isDebugFlagParam} = getPlayerParamsFromUrl();
  
  //--
  // Effect to load the url for the iframe based on the environment we're running in.
  useEffect(() => {
    async function doEffect(){
      try{
 
        const env = getEnvironment(true).toLowerCase();
        let swaUrl = await getStaticWebAppUrl(env, "player");

        if (!swaUrl){
          setError(["Unfortunately, the player module could not be loaded. Technical support has been notified.", "Could not get player app url.  Either environments.json is missing or invalid, or it does not contain key for environment: " + env]);
          return;
        }

        const mySid = sid ?? sidQueryParam;
        const myCampaignId = campaignId ?? campaignQueryParam;
        /*
         *  The SWA Player expect the url using path for subdomain, campaign, and video id:
         *  https://player.client.com/{subdomain}/{campaignId}/{videoid}
         *  
         *  The player also takes an optiona /_embed/ path to force embed mode. This should be after the subdomain
         *  https://player.client.com/{subdomain}/_embed/{campaignId}/{videoid}
         *  
         *  If not using a custom client CNAME, you must pass in the client via the cl param:
         *  https://purple-pond-0d2672c10.azurestaticapps.net/seeyouatpitt/38E8A828-D684-45CE-A627-80326E636AC3/test07082021a?cl=universitymsg.com
         *  
         *  Environment info should be passed down always as well, using &en=${[qa,dev,production]}
         *  
         *  parentWindow should capture how they landed here, and used by SWA player to allow links back to here.
         */

        const embedOrNot = isEmbeded ? "/_embed" : "";

        var params = {
          cl: "universitymsg.com",
          en: env,
          parentWindow: encodeURIComponent(window.location.href),  //We need to pass this, because the iframe child can't see our href. TODO: Policy change on our iframe to allow them to see our href?
          ...(document.referrer && {parentReferrer: encodeURIComponent(document.referrer)}) //if exists.
        };
        console.log(params);
        const urlSerchParams = new URLSearchParams(params);
        const url = new URL(swaUrl);
        url.search = urlSerchParams.toString();
        url.pathname = `${subdomain}${embedOrNot}/${myCampaignId ? `${myCampaignId}/` : ""}${mySid}`;
        console.log(`iframe url:\r\n ${url.toString()}`);

        setPlayerAppUrl(url.toString());
        
      }
      catch(ex){
        console.error("attempting to build the player static web app url", ex);
        setError(["Unfortunately, the player module could not be loaded. Technical support has been notified.", ex.toString()]);
      }
    }
  
    if(!playerAppUrl){
      doEffect()
    }
  }, [playerAppUrl, subdomain, campaignId, sid, campaignQueryParam, sidQueryParam]);

  return (
    <div id="player-container" className="app">
      <ErrorBoundary>
        {error && <ErrorCard message={error[0]} details={error[1]} />}
        {!error && playerAppUrl && 
          <div id="iframe-container" className={isEmbeded ? classes.iframeContainerEmbeded : classes.iframeContainer }>
          <iframe title="Player Body" name="playerIframe" className={isEmbeded ? classes.iframeEmbeded : classes.iframe} src={playerAppUrl} allow="fullscreen" allowFullScreen width="100%"></iframe>
          </div>
        }
      </ErrorBoundary>
    </div>
  );
}

export default PlayerFrameEmbeded;

const buildStyles   = makeStyles(theme => ({
  iframeContainerEmbeded: { 
    position: "relative", 
    paddingBottom: "56.25%", 
    height: 0, 
    overflow: "hidden", 
    maxWidth: "100%" 
  },
  iframeContainer: {
    // padding: theme.spacing(2),
    height: "100vh",
    width: "100%",
    position: "relative",
  },
  iframeEmbeded : {
    border: "none",
    position: "absolute", 
    top: 0, 
    left: 0, 
    width: "100%", 
    height: "100%"     
  }, 
  iframe:{
    // height: "53vh",
    // minHeight: 500,
    height: "100vh",
    border: "none",
    [theme.breakpoints.down("sm")]: {
      minHeight: 350
    },
    [theme.breakpoints.down("xs")]: {
      minHeight: 30
    }
  }  
}));