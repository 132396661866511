import React, { useState, useMemo } from 'react';
import * as Yup from "yup";
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { Form, Formik } from 'formik';
import { useAuthWithActions } from './auth-provider';

const loginValidation = Yup.object().shape({
  email: Yup.string("Email").required("Email is required"),
  password: Yup.string("Password").required("Password is required"),
});

const singUpValidation = Yup.object().shape({
  email: Yup.string("Email").required("Email is required"),
  password: Yup.string("Password").required("Password is required"),
  passwordConfirm: Yup.string("Confirm Password").required("Password Confirmation is required"),
});

const initValues = {
  email: "",
  password: "",
  passwordConfirm: "",
};


const AdminSignIn = () => {
  const classes   = buildStyles();
  const auth = useAuthWithActions();
  const [errorMessage, setErrorMessage] = useState(null);
  const [view, setView] = useState("login");  //"login" or "signup"
  const isLogin = useMemo(() => Boolean(view === "login"), [view]);

  const onSignUp = async (values, actions) => {
    if(!isLogin){
      if(values.password !== values.passwordConfirm){
        setErrorMessage("Passwords do not match");
        actions.setSubmitting(false);
        return;
      }
      const result = await auth.createAccount(values.email, values.password);
      if(result.isError){
        setErrorMessage("You are not permitted to access this feature.  Please contact your administrator if you believe this is a mistake.");
        actions.setSubmitting(false);
        return;
      }

      actions.setSubmitting(false);
      console.log("create account result", result);
    }
  }

  const onSignIn = async (values, actions) => {
    setErrorMessage(null);  //clear out any errors from past attempts

    if(isLogin){
      const result = await auth.signInEmail(values.email, values.password);
      if(result.isError){
        console.log("Login Error", result);
        setErrorMessage(result.error.message);
        return;
      }
      console.log("Login Success", result);
    }
    else{
      return onSignUp(values, actions);  
    }
  }

  

  return (
    <Grid id="data-view" container justify="center" className={classes.root}>
      
      <Grid item xs={12} container justify="center" className={classes.titleRow}>  
        <Grid container justify="center" className={errorMessage ? classes.mb : null}>
          {isLogin && <Typography variant="h5" color="primary" className={classes.title}>Please sign in</Typography>}
          {!isLogin && <Typography variant="h5" color="primary" className={classes.title}>Sign up below</Typography>}
        </Grid>                  
        {errorMessage && 
          <Grid className={classes.errorGrid}>
            <Typography className={classes.errorMessage}>{errorMessage}</Typography>
          </Grid>
        }  
      </Grid>
      
      <Grid item xs={12}>
        <Formik
          initialValues={initValues}
          validationSchema={isLogin ? loginValidation : singUpValidation}
          onSubmit={isLogin ? onSignIn : onSignUp}>
            {props => (
              <Form>
                <Grid container direction="column" spacing={3} alignItems="center">
                  <Grid item md={4} sm={6} xs={8} container direction="column" alignItems="center">
                    <TextField required id="email" label="Email Address" name="email" 
                      value={props.values.email} onChange={props.handleChange} onBlur={props.handleBlur}
                      helperText={props.touched.email ? props.errors.email : undefined} 
                      autoComplete="email" variant="outlined" className={classes.input} InputProps={{ classes: {root: classes.inputRoot} }} InputLabelProps={{ classes: { root: classes.labelRoot }}} margin="dense" fullWidth />
                    <TextField required type="password" id="password" label="Password" name="password" 
                      value={props.values.password} onChange={props.handleChange} onBlur={props.handleBlur}
                      helperText={props.touched.password ? props.errors.password : undefined} 
                      autoComplete="current-password" variant="outlined" className={classes.input} InputProps={{ classes: {root: classes.inputRoot} }} InputLabelProps={{ classes: { root: classes.labelRoot }}} margin="dense" fullWidth />
                    {!isLogin && 
                      <TextField required type="password" id="passwordConfirm" label="Confirm Password" name="passwordConfirm" 
                      value={props.values.passwordConfirm} onChange={props.handleChange} onBlur={props.handleBlur}
                      helperText={props.touched.passwordConfirm ? props.errors.passwordConfirm : undefined} 
                      autoComplete="current-password" variant="outlined" className={classes.input} InputProps={{ classes: {root: classes.inputRoot} }} InputLabelProps={{ classes: { root: classes.labelRoot }}} margin="dense" fullWidth />
                    }

                    <Grid container justify="center" className={classes.actionsRow}>
                      <Button type="submit" variant="outlined" color="secondary" className={classes.actionPrimary}>{isLogin ? "Log In" : "Sign Up"}</Button>
                    </Grid>                    
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>

          {isLogin && 
            <Grid container justify="center" alignItems="center">
              <Typography color="secondary">First time here?</Typography>
              <Button color="primary" onClick={() => setView("signup")}>Sign Up</Button>
            </Grid>
          }
          {!isLogin && 
            <Grid container justify="center" alignItems="center">
              <Typography color="secondary">Already have an account?</Typography>
              <Button color="primary" className={classes.action} onClick={() => setView("login")}>Sign In</Button>
            </Grid>
          }
      </Grid>
    </Grid>
  );
}

export default AdminSignIn;

const buildStyles   = makeStyles(theme => ({
  root  : {
    padding     : theme.spacing(1),
  },
  titleRow: {
    marginBottom: theme.spacing(2),
  },
  title   : {
    fontSize    : 22,
    fontWeight  : 500,
    textAlign   : "center",
    marginTop: theme.spacing(1),
  },
  mb: {
    marginBottom: theme.spacing(1),
  },
  input: {
    marginBottom: theme.spacing(1),
    "& .MuiFormHelperText-root": {
      color: theme.palette.error.light, //theme.schoolColors.color,
    },  
    "& fieldset": {
      borderColor: `${theme.schoolColors.color}66`,
      
    },
    "&:hover": {
      "& fieldset": {
        borderColor: `${theme.palette.primary.main} !important`,
      },
    } 
  },
  inputRoot: {
    "& input": {
      color: theme.schoolColors.color,      
    },
  },
  labelRoot: {
    fontSize: 15,
    color: theme.schoolColors.color,
    "&$labelFocused": {
      color: theme.palette.secondary
    }
  },
  spacer: {
    margin: `${theme.spacing(2)}px ${theme.spacing(0)}px`, //`
  },
  actionsRow: {
    marginTop: theme.spacing(2),
  },
  action: {
    margin: `${theme.spacing(2)}px ${theme.spacing(0)}px`, //`
    minWidth: theme.spacing(15),
  },
  actionPrimary: {
    minWidth: theme.spacing(20),
    marginBottom: theme.spacing(2),
  },  
  divider: {
    width: "100%",
    backgroundColor: theme.palette.primary.main,
  },
  errorGrid: {
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`, //`
    borderRadius: 5,
    border: `1px solid ${theme.palette.error.dark}`, //`
    backgroundColor: theme.palette.error.light,
    marginBottom: theme.spacing(2),
    maxWidth: "50%",
  },
  errorMessage: {
    fontSize: "1rem",
    fontWeight: 400,
    color: theme.palette.error.contrastText,
  }
}));