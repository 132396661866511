import { RPT_ACTIONS } from '../actions/report-actions';
import { createReducer } from './reducer-helpers';

//========================
// Reducer Default State
const INITIAL_STATE = {
  status    : {
    isInitialized   : false,
    isWorking       : false,
    isDownloading   : false,
    optsWorking     : false,    
    error           : null,
    pagination      : null,
    loadedAt        : null,
  },
  reportItems       : null,  
  filterOptions     : null,
};

//========================
// Reducer functions

//#region Loading Report Filters
function filtersLoading(state, action){
  return {
    ...state,
    status  : {
      ...state.status,
      optsWorking   : true,
      error         : null,
    },
    filterOptions   : null,
  }
}

function filtersLoaded(state, action){
  return {
    ...state,
    status    : {
      ...state.status,
      optsWorking     : false,
      error           : null,
    },
    filterOptions     : action.data,
  };
}

function filtersFailed(state, action){
  return {
    ...state,
    status    : {
      ...state.status,
      optsWorking     : false,
      error           : action.error,
    },
    filterOptions     : null,
  };
}
//#endregion

//#region Loading Report Items
function itemsLoading(state, action){
  return {
    ...state,
    status  : {
      ...state.status,
      isWorking     : true,
      error         : null,
      loadedAt        : null,
    },
    reportItems     : null,   //TODO: should the items disappear while loading?
  }
}

function itemsLoaded(state, action){
  //DataGrid requires an id field, so make one with the data
  const items = action.data ? action.data.map(i => { return {id: i.renderId, ...i}; }) : action.data;
  return {
    ...state,
    status    : {
      ...state.status,
      isInitialized   : true,
      isWorking       : false,
      error           : null,
      pagination      : action.pagination,
      loadedAt        : action.timestamp,
    },
    reportItems       : items, //action.data,
  };
}

function itemsFailed(state, action){
  return {
    ...state,
    status    : {
      ...state.status,
      isInitialized   : true,
      isWorking       : false,
      error           : action.error,
      pagination      : null,
      loadedAt        : null,
    },
    reportItems       : null,
  };
}
//#endregion

//#region Downloading Report
function downloading(state, action){
  return {
    ...state,
    status  : {
      ...state.status,
      isDownloading : true,
      error         : null,
    },
  }
}

function downloaded(state, action){
  return {
    ...state,
    status    : {
      ...state.status,
      isDownloading   : false,
      error           : null,
    },
  };
}

function downloadFailed(state, action){
  return {
    ...state,
    status    : {
      ...state.status,
      isDownloading   : false,
      error           : action.error,
    },
  };
}
//#endregion

//-----------------
// Action map that will be used to build the reducer
const reducerMap = {
  [RPT_ACTIONS.LOADING]     : itemsLoading,
  [RPT_ACTIONS.LOADED]      : itemsLoaded,
  [RPT_ACTIONS.FAILED]      : itemsFailed,  
  
  [RPT_ACTIONS.FILTERS_LOADING]     : filtersLoading,
  [RPT_ACTIONS.FILTERS_LOADED]      : filtersLoaded,
  [RPT_ACTIONS.FILTERS_FAILED]      : filtersFailed, 

  [RPT_ACTIONS.DOWNLOADING]     : downloading,
  [RPT_ACTIONS.DOWNLOADED]      : downloaded,
  [RPT_ACTIONS.DOWNLOAD_FAILED]      : downloadFailed, 
  
};

//The actually reducer
export default createReducer(INITIAL_STATE, reducerMap);