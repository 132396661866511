import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import thunk from 'redux-thunk';
import configureReducers from './reducers/root-reducer';
// import { routerMiddleware } from 'react-router-redux';
import { createLogger } from 'redux-logger';

const configureMiddleware = () => {
  const logger  = createLogger({collapsed : true});

  const middleware = [
    thunk,
    // routerMiddleware(history),
    logger
  ];

  // In development, use the browser's Redux dev tools extension if installed
  const enhancers = [];
  // const isDevelopment = process.env.NODE_ENV === 'development';
  // if (isDevelopment && typeof window !== 'undefined' && window.devToolsExtension) {
  //   enhancers.push(window.devToolsExtension());
  // }
  const middlewares   = composeWithDevTools(applyMiddleware(...middleware), ...enhancers);
  return middlewares;
}

export default function configureStore() {

  const initialState  = window.initialReduxState;
  const rootReducer   = configureReducers();
  const middlewares   = configureMiddleware();

  const store   = createStore(
    rootReducer,
    initialState,
    middlewares,
  );

  //For HMR, try to hot-replace the reducer, if possible (doesn't always work)
  if(process.env.NODE_ENV === "development"){
    
    console.log("attempting to hot replace reducer");

    if(module.hot){
      module.hot.accept("./reducers/root-reducer.js", () => {
        const newRoot   = require("./reducers/root-reducer").default;
        store.replaceReducer(newRoot);
      });
    }
  }

  return store;
}


