import React, { useState, useEffect } from 'react';
// import { useLocation, useRouteMatch } from 'react-router-dom';

export function getSchoolFromUrl() {
    var school = "default";

    //Need to support direct access to iFrame version in local, qa and dev
    const pathParts = window.location.pathname.split('/');
    if(pathParts.length === 3 && ["iframe", "player"].indexOf(pathParts[2].toLowerCase()) >= 0){
      school = pathParts[1];
    }
    else{
      const host      = window.location.host.toLowerCase();
      //For dev/stage we're going to use the first path to specify subdomain. https://dev.universitymsg.com/nextstopnjit/
      if (host.indexOf("dev.") >= 0 || host.indexOf("qa.") >= 0) {
          school = window.location.pathname.split('/')[1]
      } else {
          const partCount = host.indexOf("localhost") >= 0 ? 1 : 2; //localhost doesn't have the .com, so expect different parts
          const parts = host.split('.');
          school = parts.length > partCount ? parts[0] : "default";
      }
    }
    console.log("School: " + school);

  return school
}


export function getPlayerParamsFromUrl() {
  const search = new URLSearchParams(window.location.search);
  var sidQueryParam;
  var campaignQueryParam;

  //Hack to make the sid query param be case insensitive....
  for (var key of search.keys()) {
    if (key.toLowerCase() === "sid") {
      sidQueryParam = search.get(key);
    }
    else if (key.toLowerCase() === "campaignid") {
      campaignQueryParam = search.get(key);
    }
  }
  const isDebugFlagParam = search.has("debug") ? search.get("debug") : false;
  return { sidQueryParam, campaignQueryParam, isDebugFlagParam }
}
