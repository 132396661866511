import _ from 'lodash';
// import { useAuth } from "../auth/auth-provider";

const URIS = {
  base: "/api/common",
  renderQueue: "api/common/RenderQueueItem",
  environmentFileName: "environments.json"
};
const ENV_PATH = {
    development: "dev",
    qa: "qa",
    production: "prod",
    localhost: "localhost"
};

const ENV_PREFIX = "en";

//===
// Gets a search parameter from the url by its name.  assumes search will be in the form: "?key=value&key2=value2"
export function getSearchParam(key, keepCase = false) {
  let searchVal = window.location.search.slice(1);
  if(!keepCase) searchVal = searchVal.toLowerCase();
  const search = new URLSearchParams(searchVal);
  return search.get(key);
}

export function getEnvironment(includingLocalhost = false) {
  let env = getSearchParam(ENV_PREFIX);
  if(!env){
    const hostName = window.location.hostname.toLowerCase();
    // let env = "";

    if (hostName.indexOf("qa.") >= 0) {
      env = "qa";
    } else if (hostName.indexOf("dev.") >= 0) {
      env = "development";
    } else if (hostName.indexOf("localhost") >= 0) {
      env = includingLocalhost ? "localhost" : "development";
    } else {
      env = "production";
    }
  }

  return env;
};
export const getReportData = async (subdomain, user,  pageNum, pageSize, sort, filter) => {
  const fullUrl = `${URIS.base}/ReportData/${subdomain}/?${buildQueryString(pageNum, pageSize, sort, filter)}`; 
  const response = await doFetchWithToken(fullUrl, "GET", null, user.token);
  return response;
}

export const getReportFilters = async (subdomain, user ) => {
  const fullUrl = `${URIS.base}/ReportFilterOptions/${subdomain}/`; 
  const response = await doFetchWithToken(fullUrl, "GET", null, user.token);
  return response;
}

export const getReportDownload = async (subdomain, user, sort, filter) => {
  const fullUrl = `${URIS.base}/ReportExport/${subdomain}/?${buildQueryString(null, null, sort, filter)}`;
  const response = await doFileFetchWithToken(fullUrl, "GET", null, user.token, "text/csv");
  return response;
}

//------------------
// Creates a query string out of the various pagination properties
export function buildQueryString(pageNum, pageSize, sort, filter) {
  let qs = new URLSearchParams("");
  if (pageNum) qs.append("offset", (pageNum * pageSize));
  if (pageSize) qs.append("limit", pageSize);
  if (sort) qs.append("sort", encodeURIComponent(sort));
  if (filter) qs.append("filter", encodeURIComponent(filter));
  return qs.toString();
}

//------------------
// Handle a blob/arraybuffer response and trigger the download
const CD_HEADER = "content-disposition";
const CD_HEADER_CAP = "Content-Disposition";    //historically, it has come back both ways

export async function doFetch(url, method, body) {

  try {
    const result = await fetch(url, { method: method, body: body });
    if (result.json) {
      const json = await result.json();

      if (!result.ok) {
        return {
          isError: true,
          status: result.status,
          message: result.statusText,
          ...json
        };
      }

      return json;  //all good, just return the result
    }
    else if (result.ok === true) {
      //Nothing to return, but all is good
      return result;
    }
  }
  catch (err) {
    let errMsg = "Unhandled exception during fetch. Our technical team has been notified.";
    if (err.text) errMsg = await err.text();

    return {
      isError: true,
      message: errMsg
    };
  }
}


export async function doFetchWithToken(url, method, body, token) {

  try {
    const headers = {
      "Authorization": "Bearer " + token,
    };

    const result = await fetch(url, { method: method, body, headers });
    if (result.json) {
      const json = await result.json();

      if (!result.ok) {
        return {
          isError: true,
          status: result.status,
          message: result.statusText,
          ...json
        };
      }

      return json;  //all good, just return the result
    }
    else if (result.ok === true) {
      //Nothing to return, but all is good
      return result;
    }
  }
  catch (err) {
    let errMsg = "Unhandled exception during fetch. Our technical team has been notified.";
    if (err.text) errMsg = await err.text();

    return {
      isError: true,
      message: errMsg
    };
  }
}


async function doFileFetchWithToken(url, method, body, token, contentType) {
  try {
    const headers = {
      "Authorization": "Bearer " + token,
    };

    const result = await fetch(url, { method: method, body, headers });
    if (result.ok) {
      const isOk = await downloadFile(result, contentType);
      if (!isOk) {
        return {
          isError: true,
          status: result.status,
          message: result.statusText,
          ...result,
        };
      }
      else {
        return {
          isError: false,
          ...result,
        }
      }
    }
    else {
      return {
        isError: true,
        error: result.statusText || "Failed to fetch file for download",
        ...result,
      }
    }
  }
  catch (err) {
    let errMsg = "Unhandled exception during fetch. Our technical team has been notified.";
    if (err.text) errMsg = await err.text();

    return {
      isError: true,
      error: errMsg
    };
  }
}


export async function downloadFile(result, mimeType, isRedirect) {
  const cd = result.headers.get(CD_HEADER) || result.headers.get(CD_HEADER_CAP);

  if (cd) {
    //The file to download was returned as a Blob in the response.
    let fileName = null;
    const cdParts = cd.split(';');
    const fname = _.find(cdParts, cdp => cdp.indexOf("filename=") >= 0);
    if (fname) {
      fileName = fname.trim().substr("filename=".length);
    }

    if (fileName && mimeType) {
      var linkElement = document.createElement('a');
      try {
        var data = await result.blob();
        var blob = new Blob([data], { type: mimeType });
        var url = window.URL.createObjectURL(blob);

        linkElement.setAttribute('href', url);
        linkElement.setAttribute("download", fileName);

        var clickEvent = new MouseEvent("click", {
          "view": window,
          "bubbles": true,
          "cancelable": false
        });
        linkElement.dispatchEvent(clickEvent);

        return true;    //indicate it worked

      } catch (ex) {
        console.log(ex);
      }
    }
  }

  return false;
}

export function buildVideoUrl(subdomain, sid){
  // const backendUrl = window.location.origin + "/api/common/RenderQueueItem/";
  const url = `${window.location.origin}/${URIS.renderQueue}/${subdomain}/${sid}`;
  return url;
}

//===
// Fetches a file from a specific url as JSON and returns a JSON object
//
export async function fetchJsonFile(fileUrl) {
  try {
    const result = await fetch(fileUrl, { method: "GET" });
    if (result.json) {
      const json = await result.json();

      if (!result.ok) {
        return {
          isError: true,
          status: result.status,
          message: result.statusText,
          ...json
        };
      }

      return json;  //all good, just return the result
    }
    else if (result.ok === true) {
      //Nothing to return, but all is good
      return result;
    }
  }
  catch (err) {
    let errMsg = "Unhandled exception during fetch. Our technical team has been notified.";
    if (err.text) errMsg = await err.text();

    return {
      isError: true,
      message: errMsg
    };
  }
}

//
//  We have three static web app types: reporting, player, app
//  And three environments: development, production, qa
//  Each point to a distince Static Web App host.
//
export async function getStaticWebAppUrl(env, app = "reporting") {

    var bootstrap_path;

    const hostName = window.location.hostname.toLowerCase();
    if (hostName.indexOf("localhost") >= 0) {
        bootstrap_path = "http://localhost:8100/personicom.com/bootstrap/";
    } else {
        bootstrap_path = "https://personicommedia.blob.core.windows.net/bootstrap/";
    }


 

  ///TODO: replace universitymsg with cl/client.
    var clientSpecificFileName = "universitymsg." + URIS.environmentFileName;
    const jsonClientUrl = bootstrap_path + ENV_PATH[env.toLowerCase()] + "/" + clientSpecificFileName;
    const jsonRootUrl = bootstrap_path + ENV_PATH[env.toLowerCase()] + "/" + URIS.environmentFileName;

    console.log("jsonClientUrl: " + jsonClientUrl);
    console.log("jsonRootUrl: " + jsonRootUrl);

    const jsonClientSpecific = await fetchJsonFile(jsonClientUrl);
    const jsonRoot = await fetchJsonFile(jsonRootUrl);

  if (jsonRoot.isError) {
    throw new Error(jsonRoot.message);
  }

  var json = jsonRoot;
  if (!jsonClientSpecific.isError) {
    json = { ...jsonRoot, ...jsonClientSpecific };
  } 
  return json[`${app.toLowerCase()}-static-web-app`][env] || "";
}