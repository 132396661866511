import  firebaseApp from "./firebase-config";
import "firebase/firestore";
// import { boolean } from "yup/lib/locale";

const COLS = {
  vod: "vod",
};
const DOCS = {
  admins: "all",
}

const validateUser = (doc, email) => {
  if(doc.exists){
    const whitelist = doc.data().whitelist;
    const found = whitelist.find(u => u === email);
    return Boolean(found);
  }
  return false;
}

//== Api to access authentication information in Firestore
export const authApi = ({

  //--Checks to see if an email address is on the whitelist
  checkWhitelist: async (school, email) => {
    const db = firebaseApp.firestore();
    const users = await db.collection(COLS.vod).doc(school).get();
    if(validateUser(users, email)){
      return true;
    }
    else{
      //Check to see if they're an admin that can see anything
      const admins = await db.collection(COLS.vod).doc(DOCS.admins).get();
      return validateUser(admins, email);
    }
  },

});