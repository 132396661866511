import React from 'react';
import AppRouting from "./appRouting";
import { Grid } from "@material-ui/core";
import { useEffect, useState } from "react";
import CustomizationsProvider, { CustomizationHelmet } from "@personicom/customizations";
import { getBaseUrl, getClientFromUrl, getEnvironmentFromUrl, getSubdomainFromUrl, ErrorCard, LoadingIndicator } from "@personicom/customizations";


//TODO: do we still need to use GA at all?
const GA_TRACKER = "UA-102377011-8";
const GA_TRACKER_SECONDARY = "UA-157273197-1";

const App = () => {
    const [isReady, setReady] = useState(false);
    const [error, setError] = useState(null);
    const [path, setPath] = useState("");
    useEffect(() => {
        async function initialize() {
            try {
                var client = await getClientFromUrl();
                var env = await getEnvironmentFromUrl();
                var sd = getSubdomainFromUrl();
                console.log(`Client: ${client}\r\nSD: ${sd}\r\nEnv: ${env}`);
                const basePath = await getBaseUrl();
                setPath(basePath);
                setReady(true);
            }
            catch (ex) {
                setError(ex);
            }
        }

        initialize();
    }, []);

    if (error) {
        return (
            <Grid container direction="column" alignItems="center" style={{ marginTop: "10%" }}>
                <ErrorCard message={`This appears to be an invalid domain. Please try again with a valid url.`} details={error.toString()} />
            </Grid>
        );
    }
    else if (!isReady) {
        return <LoadingIndicator />;
    }
    else {
        return (
            <CustomizationsProvider path={path}>
                <CustomizationHelmet gaTrackerId={GA_TRACKER} gaTrackerSecondaryId={GA_TRACKER_SECONDARY} />
                <AppRouting />
            </CustomizationsProvider>
        );
    }
}

export default App;