import { getReportData, getReportDownload, getReportFilters } from '../../helpers/api-helper';

//===============
// The Action Types

const LOADING = "RPT_LOADING", LOADED = "RPT_LOADED", FAILED = "RPT_FAILED";
const FILTERS_LOADING = "RPT_FILTERS_LOADING", FILTERS_LOADED = "RPT_FILTERS_LOADED", FILTERS_FAILED = "RPT_FILTERS_FAILED";
const DOWNLOADING = "RPT_DOWNLOADING", DOWNLOADED = "RPT_DOWNLOADED", DOWNLOAD_FAILED = "RPT_DOWNLOAD_FAILED";

export const RPT_ACTIONS  = {
  LOADING, LOADED, FAILED,
  FILTERS_LOADING, FILTERS_LOADED, FILTERS_FAILED,
  DOWNLOADING, DOWNLOADED, DOWNLOAD_FAILED,
};

//-----------
// Gets the report results that match the current page and filter
export const loadReports = (subdomain, user, pageNum, pageSize, sort, filter) => async(dispatch, getState) => {

  console.log("loadReports called with parameters", pageNum, pageSize, sort, filter);
  
  dispatch({type: LOADING});

  const result = await getReportData(subdomain, user, pageNum, pageSize, sort, filter);
  if(result.isError){
    //TODO: test out the error case
    dispatch({type: FAILED, error: result.error});
  }
  else{    
    dispatch({type: LOADED, data: result.items, pagination: result.options, timestamp: result.timestamp});
  }
}

//-----------
// Downloads the report data as a csv
export const downloadReport = (subdomain, user, sort, filter) => async(dispatch, getState) => {

  console.log("downloadReport called with parameters", sort, filter);
  
  dispatch({type: DOWNLOADING});

  const result = await getReportDownload(subdomain, user, sort, filter);
  if(result.isError){
    //TODO: test out the error case
    dispatch({type: DOWNLOAD_FAILED, error: result.error});
  }
  else{    
    dispatch({type: DOWNLOADED, data: result});
  }
}

//-----------
// Gets the filter options for the reports list
export const loadReportFilterOptions = (subdomain, user ) => async(dispatch, getState) => {

  dispatch({type: FILTERS_LOADING});

  const result = await getReportFilters(subdomain, user);
  if(result.isError){
    //TODO: test out the error case
    dispatch({type: FILTERS_FAILED, error: result.error});
  }
  else{    
    dispatch({type: FILTERS_LOADED, data: result});
  }
}