import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation, useParams } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import { Button, Grid, Menu, MenuItem, Tooltip, Typography } from '@material-ui/core';
import { useAuthWithActions } from './auth-provider';
import { useAnchor } from 'helpers/general-hooks';
import { fetchJsonFile } from 'helpers/api-helper';
import { useCustomizations } from "@personicom/customizations";

const defaultMenuItems = [
    { id: "logout", label: "Logout", isAuthenticated: true, actionPath: "/logout" },
    { id: "reports", label: "Reports", isAuthenticated: true, actionPath: "/reports" },
    // { id: "sep", isSeparator: true, isAuthenticated: true },
];
const defaultAppMenuItem = { id: 30, label: "Render Request", isAuthenticated: true, actionPath: "/app" };

//== Gets the appropriate path for an app "application" based on the appConfig and the fallback / defaults.
const getAppPath = (appConfig, subdomain) => {
    if (appConfig.isDefault) {
        return `${subdomain ? `/${subdomain}` : ''}/app`;
    }
    else {
        return `${subdomain ? `/${subdomain}` : ''}/app/${appConfig.urlSlug ?? appConfig.id}`;
    }
}

const AuthContainer = ({ children }) => {
    const classes = buildStyles();
    const { blobUrl } = useCustomizations();
    const location = useLocation();
    const { subdomain } = useParams();
    const history = useHistory();
    const auth = useAuthWithActions();
    const [menuItems, setMenuItems] = useState([]);
    const [anchor, isOpen, onOpen, onClose, position] = useAnchor();
    const availableMenuItems = useMemo(() => menuItems.filter(itm => itm.isAuthenticated === auth.isAuthenticated), [menuItems, auth.isAuthenticated]);

    const menuClicked = useCallback((item) => async () => {
        if (item.actionPath === "/logout") {
            await auth.signOut();
            return;
        }
        else if (item.actionPath !== location.pathname) {
            history.push(item.actionPath);
        }
        onClose();
    }, [auth, location, history, onClose]);

    useEffect(() => {
        async function doEffect() {
            var menu = [];
            try {
                const appsUrl = blobUrl("applications.json");
                const appsJson = await fetchJsonFile(appsUrl);
                if (appsJson?.applications) {
                    console.log("applications:", appsJson);
                    menu = appsJson.applications.reduce((output, item) => {
                        const menuItem = { id: item.id, label: item.label, isAuthenticated: true, actionPath: getAppPath(item, subdomain) };
                        output.push(menuItem);
                        return output;
                    }, [...defaultMenuItems]);
                }
                else {
                    console.log("no applications.json found, using defaults");
                }
            }
            catch (ex) {
                console.error("failed to load applications.json, using default apps", ex);
            }

            if (menu.length === 0) menu = [...defaultMenuItems, defaultAppMenuItem];
            setMenuItems(menu);
        }
        doEffect()
    }, []);

    return (
        <Grid id="auth-container">
            <Grid item xs={12} container justifyContent="flex-end" alignItems="center" className={classes.authHeader} wrap="nowrap">

                {auth.isAuthenticated &&
                    <Grid container justifyContent="flex-end" alignItems="center">
                        <Tooltip title={`Logged in as ${auth.user.displayName || auth.user.email}`}>
                            <Button variant="outlined" className={classes.headerButton} onClick={onOpen}>
                                <Grid container justifyContent="flex-end" alignItems="center">
                                    <i className="fa fa-user-circle" />
                                    <Typography className={classes.username}>{auth.user.displayName || auth.user.email}</Typography>
                                </Grid>
                            </Button>
                        </Tooltip>
                    </Grid>
                }
                <Menu
                    id="menu-appbar"
                    anchorEl={anchor}
                    keepMounted
                    open={isOpen}
                    onClose={onClose}
                    {...position}
                >
                    {availableMenuItems.map(item => <MenuItem key={item.id} onClick={menuClicked(item)}>{item.label}</MenuItem>)}
                </Menu>

            </Grid>
            <div>
                {children}
            </div>
        </Grid>
    );
}

export default AuthContainer;

const buildStyles = makeStyles(theme => ({
    authHeader: {
        padding: `${theme.spacing(0.5)}px ${theme.spacing(1)}px`, //`
        marginBottom: theme.spacing(1),
    },
    username: {
        marginLeft: theme.spacing(1),
        color: theme.schoolColors.color,
    },
    action: {
        paddingTop: 0,
        paddingBottom: 0,
    },
    headerButton: {
        border: "none",
        "& i": {
            color: theme.schoolColors.color,
        }
    }
}));