import React from 'react';
import AppHeader from '../../app-header';
// import ReportsBody from './reports-body';
// import TelemetryProvider from '../../helpers/telemetry-provider';
import { AuthProvider } from '../../auth/auth-provider';
import Grid from "@material-ui/core/Grid";
import ErrorCard from "../../helpers/error-card";
import ReportsFrame from './reports-frame';

function ReportsContainer(props) {
  return (
    <div className="app">
      <AuthProvider>
        <AppHeader />
        <ReportsFrame />
      </AuthProvider>
    </div>
  );
}

export default ReportsContainer;