import React, { useState, useEffect } from "react";
import ErrorCard from "helpers/error-card";
import { useAuth } from "auth/auth-provider";
import { getEnvironment, getStaticWebAppUrl } from "helpers/api-helper";
import { Grid, makeStyles } from "@material-ui/core";
import AdminSignIn from "auth/admin-signin";
import AuthContainer from "auth/auth-container";
import ErrorBoundary from "helpers/error-boundary";
import { useParams } from "react-router";
import { useCustomizations } from "@personicom/customizations";

const VodFrame = () => {
  const { isAuthenticated, user } = useAuth();
  const { application } = useParams();
  const [appUrl, setAppUrl] = useState(null);
  const [error, setError] = useState(null);
  const [lastApp, setLastApp] = useState(null);
  const classes = buildStyles();
    const { subdomain } = useCustomizations();


  //--
  // Effect to load the url for the iframe based on the environment we're running in.
  useEffect(() => {
    async function doEffect(){
      try{
        const env = getEnvironment(true).toLowerCase();
        let url = await getStaticWebAppUrl(env, "app");

        if (!url) {
          setError(["Unfortunately, the on-demand video module could not be loaded. Technical support has been notified.", "Could not get app url.  Either environments.json is missing or invalid, or it does not contain key for environment: " + env]);
          return;
        }

        const appPath = application ? `/${application}` : "";   //for any secondary apps that will be at /[app-slug]
        setLastApp(application); //track this so we know to refresh when it changes

        //Sample: http://app.universitymsg.com.localhost:3002/pittcomingsoon/app
          url = `${url}/${subdomain}${appPath}/?cl=universitymsg.com&en=${env}&token=${encodeURIComponent(user.token)}&parentWindow=${encodeURIComponent(window.location.href)}`
        
        setAppUrl(url);
      }
      catch(ex){
        console.error("attempting to build the video on demand static web app url", ex);
        setError(["Unfortunately, the video on demand module could not be loaded. Technical support has been notified.", ex.toString()]);
      }
    }
  
    if(user && user.token && (!appUrl || lastApp !== application)){
      // console.log("vod-frame is re-rendering, application: ", application);
      doEffect();
    }
  }, [appUrl, user, application, lastApp]);

  // console.log("user (from vod frame)", user);
  return (
    <Grid container direction="column" className={classes.appBody}>
      {!isAuthenticated &&
        <AdminSignIn />
      }
      {isAuthenticated &&
        <AuthContainer>
          <ErrorBoundary>
            {error && 
              <ErrorCard message={error[0]} details={error[1]} />
            }
            {!error && appUrl && 
              <div className={classes.iframeContainer}>
                <iframe title="App Body" className={classes.iframe} src={appUrl} allow="clipboard-write" width="100%"></iframe>
              </div>
            }
          </ErrorBoundary>
        </AuthContainer>
      }
    </Grid>
  );
};

export default VodFrame;

const buildStyles   = makeStyles(theme => ({
  appBody: {
    flexWrap: "nowrap",
    textAlign: "center",
    padding: 5,
    borderBottom: `5px solid ${theme.schoolColors.borderColor}` //`
  },
  iframeContainer: {
    padding: theme.spacing(2),
  },
  iframe: {
    height: "calc(100vh - 210px)",
    border: "none",
  }
  
}));